import * as React from 'react'
import { withPrismicPreviewResolver } from 'gatsby-plugin-prismic-previews'

const PreviewPage = ({ isPreview, ...props }) => {
  if (isPreview === false) return 'Not a preview!'

  return (
    <div className="text-green bg-cream flex items-center justify-center h-screen w-screen text-2xl">
      <p>Loading...</p>
    </div>
  )
}

export default withPrismicPreviewResolver(PreviewPage)
